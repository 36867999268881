import no from '~/i18n/no.json'
import en from '~/i18n/en.json'
export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'no',
    messages: {
        en,
        no
    },
    missing: (locale, key) => {
        return '';
    },

}))
